import { log } from './logger';
import { status } from './status';
import { track } from './track';
import { identify } from './identify';
import { produceQueue, startQueue } from './queue';

type Init = {
  token?: string | null;
  appName?: string | null;
  appVersion?: string | null;
};

/**
 * init steps
 * 1. listen to navigation changes to track page view
 * 2. listen to JS unload event: we should not loose any tracked event, even if the user closes the app too soon
 * 3. update cookies: make sure we get the token and saves it
 * 4. update fingerprint
 * 5. register device and track session start
 */
function init(args: Init | string | null = {}) {
  let token;
  let appName;
  let appVersion;

  if (typeof args === 'string') {
    token = args;
  }

  if (!!args && !!args.token) {
    token = args.token;
    appName = args.appName;
    appVersion = args.appVersion;
  }

  if (!token) {
    log('Error', 'Invalid token');
    return;
  }

  startQueue();

  produceQueue([
    // 1
    { executor: 'listenToNavigation', params: {} },
    // 2
    { executor: 'listenToJavaScriptUnloadEvent', params: {} },
    // 3
    { executor: 'bootstrap', params: { token } },
    // 4
    { executor: 'calculateFingerprint', params: {} },
    // 5
    { executor: 'getOrCreateCookieRegisterDeviceAndStartSession', params: { appName, appVersion } },
  ]);
}

const angora = {
  identify,
  init,
  track,
  status,
};

Object.freeze(angora);

window.angora = angora;

export default angora;
