import { ANGORA_ENV, COMMIT_SHA, PACKAGE_VERSION } from './config';
import { log } from './logger';
import { fetchQuery } from './fetchQuery';

const query = `query SQ { s { e v c } }`;

export function status() {
  const args = {
    request: { name: 'SQ', query },
    variables: {},
  };

  fetchQuery(args, data => {
    const sdk = { e: ANGORA_ENV, v: PACKAGE_VERSION, c: COMMIT_SHA };
    log('Sdk', JSON.stringify(sdk, undefined, 2));
    log('Server', JSON.stringify(data && data.s, undefined, 2));
  });
}
