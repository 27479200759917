import { fetchQuery } from './fetchQuery';
import { credentials } from './localStorage';
import { COMMIT_SHA, COOKIE_KEY } from './config';
import { unbase64 } from './base64';

const sERMutation = `mutation SERMutation($input: SERInput!) { SER(input: $input) { e } }`;

type SdkErrorReport = { executor: string; params: { [key: string]: string } | {}; queueSize: number };

export function sdkErrorReport({ executor, params = {}, queueSize }: SdkErrorReport) {
  const application = JSON.parse(unbase64(credentials[COOKIE_KEY.APPLICATION]));
  const cookie = JSON.parse(unbase64(credentials[COOKIE_KEY.COOKIE]));
  const input = {
    tk: application.token,
    av: application.appversion,
    ap: application.appplatform,
    tz: application.timezone,
    ci: cookie.id,
    ex: executor,
    pm: JSON.stringify(params),
    qs: queueSize,
    cs: COMMIT_SHA,
  };

  const args = {
    request: { name: 'SER', query: sERMutation },
    variables: { input },
  };
  fetchQuery(args, data => {
    if (!data || !data.SER || data.SER.e) {
      // @TODO - do something about this
      // changeState(STATES.ERROR);
    } else {
      // @TODO - do something about this
      // changeState(STATES.SUCCESS);
    }
  });
}
