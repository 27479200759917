import { GRAPHQL_SDK_URL } from './config';
import { fetchQuery, FetchQuery } from './fetchQuery';
import { encodedCredentials } from './localStorage';

export function sendBeacon(args: FetchQuery) {
  if (!navigator.sendBeacon) {
    fetchQuery(args);
  } else {
    const { request, variables } = args;
    navigator.sendBeacon(
      GRAPHQL_SDK_URL +
        '?query=' +
        encodeURIComponent(request.query) +
        '&variables=' +
        encodeURIComponent(JSON.stringify(variables)) +
        '&cookie=' +
        encodedCredentials,
    );
  }
}
