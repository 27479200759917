import { bootstrap } from './bootstrap';
import { calculateFingerprint } from './fingerprint';
import { endSession, listenToJavaScriptUnloadEvent, getOrCreateCookieRegisterDeviceAndStartSession } from './session';
import { listenToNavigation, pageView } from './pageView';
import { executeTrack } from './track';
import { executeIdentify } from './identify';
import { sdkErrorReport } from './recovery';

export const STATES = {
  EMPTY: 'EMPTY', // idle state, just waiting for the first event jumps into the queue
  NEXT: 'NEXT', // decision state, or starts consuming or go back to empty state
  CONSUMING: 'CONSUMING', // it actually executes the event and waits someone triggers success or error
  ERROR: 'ERROR', // recovery state, it should get things back working
  SUCCESS: 'SUCCESS', // complete state, just to say a job was successfully executed
};

/**
 *                       -------------------|
 *                      |              -> error
 *                     \/            /
 * start -> empty <-> next -> consuming
 *                    /\             \
 *                    |               -> success
 *                    ---------------------|
 */
const allowedStatesChanges = {
  EMPTY: [STATES.NEXT],
  NEXT: [STATES.EMPTY, STATES.CONSUMING],
  CONSUMING: [STATES.ERROR, STATES.SUCCESS],
  ERROR: [STATES.NEXT],
  SUCCESS: [STATES.NEXT],
};

let errorCounter = 1;

const executors = {
  defaultExecutor: () => changeState(STATES.SUCCESS),
  bootstrap: args => bootstrap(args),
  calculateFingerprint: () => calculateFingerprint(),
  getOrCreateCookieRegisterDeviceAndStartSession: args => getOrCreateCookieRegisterDeviceAndStartSession(args),
  listenToNavigation: () => listenToNavigation(),
  listenToJavaScriptUnloadEvent: () => listenToJavaScriptUnloadEvent(),
  endSession: () => endSession(),
  pageView: args => pageView(args),
  executeTrack: args => executeTrack(args),
  executeIdentify: args => executeIdentify(args),
};

const state = {
  value: STATES.EMPTY,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  valueListener: function(args) {},
  set state(newState) {
    this.value = newState;
    this.valueListener(newState);
  },
  get state() {
    return this.value;
  },
  registerListener: function(listener) {
    this.valueListener = listener;
  },
};

export const queue = [];

export function startQueue() {
  state.registerListener(function(args) {
    consumeQueue(args);
  });
}

export function changeState(value?: string | null) {
  if (typeof value !== 'string') {
    return;
  }

  const newState = STATES[value];

  // does this new state exists?
  if (!!newState) {
    // does the current state allows change to this new state?
    if (allowedStatesChanges[state.state].includes(newState)) {
      state.state = newState;
    }
  }
}

function consumeQueue(args) {
  if (args === STATES.NEXT) {
    if (queue.length > 0) {
      changeState(STATES.CONSUMING);
    } else {
      changeState(STATES.EMPTY);
    }
  }

  if (args === STATES.SUCCESS) {
    queue.shift();
    changeState(STATES.NEXT);
  }

  if (args === STATES.ERROR) {
    if (errorCounter >= 12) {
      sdkErrorReport({ executor: queue[0].executor, params: queue[0].params, queueSize: queue.length });
    }

    setTimeout(() => {
      /*
       * @TODO - improve this based on server or sdk errors
       * being online/offline may not be the root cause
       * the problem may be related to our server or sdk
       * possible server/sdk errors:
       * 1. could not auth with token
       * 2. could not register device
       * 3. could not persist cookies
       * 4. could not start a session
       * */
      if (navigator.onLine) {
        changeState(STATES.NEXT);
      }
      // @TODO - retry ms should increase proportionally to the number of attempts
    }, errorCounter * 1000);
    errorCounter = errorCounter < 15 ? errorCounter + 2 : 1;
  }

  if (args === STATES.CONSUMING) {
    const { executor = 'defaultExecutor', params = {} } = queue[0];
    if (typeof executors[executor] === 'function') {
      executors[executor](params);
    } else {
      changeState(STATES.SUCCESS);
    }
  }
}

export function produceQueue(args) {
  if (Array.isArray(args)) {
    const validArgs = args.filter(item => !!item && typeof item.executor === 'string');
    if (validArgs.length > 0) {
      queue.push(...validArgs);
      changeState(STATES.NEXT);
    }
  }
}
