import React, { useMemo, useState } from 'react';
import { Row, Col, Drawer, Button } from 'antd';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { CopyOutlined, FolderOutlined, DownOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';

import { HOSTNAME } from '../../config';

import useTranslation from '../../locales/useTranslation';
import useHistory from '../../locales/useHistory';

import Link from '../common/Link';
import Space from '../common/Space';

import logo from '../../images/logo.svg';
import whiteLogo from '../../images/headerWhiteLogo.svg';
import Text from '../common/Text';

const Container = styled.div`
  padding: 48px 32px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (min-width: 971px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

const Logo = styled.img`
  width: 164px;
`;

const Dropdown = styled.div<{ isDropDownVisible: boolean }>`
  transition: all 0.1s linear;
  max-height: ${p => !p.isDropDownVisible && '0'};
  opacity: ${p => !p.isDropDownVisible && '0'};
`;

const SubMenu = styled(Row)`
  flex: 1;
  padding: 20px 40px;
  flex-wrap: unset !important;
  align-items: baseline;
`;

const CustomDownOutlined = styled(DownOutlined)<{ isDropDownVisible: boolean }>`
  transition: transform 0.3s;
  transform: ${p => p.isDropDownVisible && 'rotate(-180deg)'};
  font-size: 14px;
  color: ${p => p.theme.color.darkBlue};
`;

const pricingRegex = new RegExp('/pricing', 'i');

const MobileHeader = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDropDownVisible, setDropDownVisible] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const resourcesSubMenus = [
    {
      icon: CopyOutlined,
      title: t['Blog'],
      description: t['ReadOurContentAboutTheWorld'],
      href: `https://blog.${HOSTNAME}`,
    },
    {
      icon: FolderOutlined,
      title: t['Doc'],
      description: t['TechnicalQuestionsOnHowToApplyAngora'],
      href: `https://docs.${HOSTNAME}`,
    },
  ];

  const isPricingRoute = useMemo(() => pricingRegex.test(location.pathname), [location.pathname]);

  const handleClick = () => {
    history.push('/contact');
    setDrawerOpen(false);
  };

  const header = (
    <Row align="middle" justify="space-between">
      <Link to="/" onClick={() => setDrawerOpen(false)}>
        <Logo src={logo} alt="Angora" />
      </Link>
      <Row align="middle" justify="center" onClick={() => setDrawerOpen(false)} style={{ cursor: 'pointer' }}>
        <CloseOutlined style={{ fontSize: 18, color: '#111E42' }} />
      </Row>
    </Row>
  );

  return (
    <Container>
      <Row align="middle" justify="space-between">
        <Col>
          <Link to="/">
            <img src={isPricingRoute ? whiteLogo : logo} alt="Angora" />
          </Link>
        </Col>
        <Col>
          <MenuOutlined
            onClick={() => setDrawerOpen(true)}
            style={{ fontSize: 24, color: isPricingRoute ? '#fff' : '#354675' }}
          />
        </Col>
      </Row>

      <Drawer
        title={header}
        width="100%"
        onClose={() => setDrawerOpen(false)}
        visible={isDrawerOpen}
        bodyStyle={{ padding: '0' }}
        headerStyle={{ borderBottom: 'none', padding: '32px 32px 0' }}
        closable={false}
      >
        <MenuWrapper>
          <Space height={100} />
          <Link font="menuLink" color="darkBlue" to="/pricing" onClick={() => setDrawerOpen(false)}>
            {t['Pricing']}
          </Link>
          <Space height={100} />
          <Link font="menuLink" color="darkBlue" to="/contact" onClick={() => setDrawerOpen(false)}>
            {t['ContactUs']}
          </Link>
          <Space height={100} />

          <Row align="middle" onClick={() => setDropDownVisible(!isDropDownVisible)}>
            <Text font="menuLink" color="darkBlue">
              {t['Resources']}
            </Text>
            <Space width={5} />
            <CustomDownOutlined isDropDownVisible={isDropDownVisible} />
          </Row>
          <Dropdown isDropDownVisible={isDropDownVisible}>
            {resourcesSubMenus.map(subMenu => (
              <Row key={subMenu.title}>
                <a href={subMenu.href} target="_blank" rel="noopener noreferrer">
                  <SubMenu gutter={[8, 0]}>
                    <Col style={{ paddingLeft: 0 }}>
                      <subMenu.icon style={{ fontSize: 16, color: '#6D7EA8' }} />
                    </Col>
                    <Col style={{ paddingRight: 0 }}>
                      <Text font="button">{subMenu.title}</Text>
                      <Space height={6} />
                      <Text font="button" color="lightBlue">
                        {subMenu.description}
                      </Text>
                    </Col>
                  </SubMenu>
                </a>
              </Row>
            ))}
          </Dropdown>
          <Space height={isDropDownVisible ? 40 : 100} />
          <Button type="primary" onClick={handleClick}>
            <Text font="link" fontWeight="600" color="white">
              {t['GetStartedForFree']}
            </Text>
          </Button>
        </MenuWrapper>
      </Drawer>
    </Container>
  );
};

export default MobileHeader;
