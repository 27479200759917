import React from 'react';
import styled from 'styled-components';

import useHistory from '../../locales/useHistory';

import Text, { TextProps } from './Text';

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

interface LinkProps extends TextProps {
  children: React.ReactNode;
  to: string;
  onClick?(): void;
}

const Link = ({ children, to, onClick, ...props }: LinkProps) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
    onClick && onClick();
  };

  return (
    <Button onClick={handleClick}>
      <Text {...props} span>
        {children}
      </Text>
    </Button>
  );
};

export default Link;
