import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useLocation } from '@reach/router';

import { useScroll } from '../../hooks/useScroll';

import { NODE_ENV } from '../../config';

import GlobalStyles from './GlobalStyles';
import theme from './theme';

import Header from './Header';
import MobileHeader from './MobileHeader';
import Footer from './Footer';

const Limiter = styled.div<{ background: string }>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  background: ${p => p.background};
`;

interface LayoutProps {
  children: React.ReactNode;
}

const pricingRegex = new RegExp('/pricing', 'i');
const contactRegex = new RegExp('/contact', 'i');

const __DEV__ = NODE_ENV === 'development';

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const [background, setBackground] = useState('');

  const [isOver, scrollingUp] = useScroll(110);

  useEffect(() => {
    if (__DEV__) {
      require('@angoralabs/angora-js');

      angora.init(
        'ZTc1NDcyMTUtNjMwNC01MTcwLTljNDEtM2QwODgwZDE1OGYyOmU4MzM4ZGNmYTI0Y2Q4YTMxZjE5M2I2OGU5OTA2YTdhNjgwN2Q3YzM=',
      );
    }
  }, []);

  useEffect(() => {
    if (pricingRegex.test(location.pathname)) {
      setBackground('linear-gradient(180deg, #6d7ea8 0%, #354675 100%)');
    } else if (contactRegex.test(location.pathname)) {
      setBackground('#fff');
    } else {
      setBackground('#f8f8fa');
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <Limiter background={background}>
        <MobileHeader />
        <Header isOver={isOver} scrollingUp={scrollingUp} />
        {children}
      </Limiter>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
