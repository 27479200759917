import React, { useCallback } from 'react';
import { Dropdown as AntdDropdown, Menu, Row, Col, Button } from 'antd';
import styled, { css } from 'styled-components';
import { ClickParam } from 'antd/lib/menu';
import { useLocation, useNavigate } from '@reach/router';
import { CaretDownFilled } from '@ant-design/icons';

import useTranslation from '../../locales/useTranslation';
import Space from '../common/Space';
import Link from '../common/Link';

import whiteLogo from '../../images/whiteLogo.svg';

const colCss = css`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled(Row)`
  background: #0e1732;
  padding: 24px 48px 24px 24px;
  @media (max-width: 992px) {
    padding: 48px;
    ${colCss}
  }
`;

const Links = styled(Row)`
  margin: 0 !important;
  @media (max-width: 992px) {
    ${colCss}
  }
`;

const linkCss = css`
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

const SiteInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, isPortuguese } = useTranslation();

  const handleLanguageChange = useCallback(
    ({ key }: ClickParam) => {
      if (key === 'pt' && isPortuguese) return;
      if (key === 'en' && !isPortuguese) return;

      if (key === 'pt') {
        let to = location.pathname.replace('/', '');
        to = to ? `/${to}` : '';
        navigate(`/pt${to}`);
      } else {
        let to = location.pathname.replace('/pt', '');
        to = to ? `/${to}` : '/';
        navigate(to);
      }
    },
    [isPortuguese, location.pathname, navigate],
  );

  const langs = (
    <Menu onClick={handleLanguageChange}>
      <Menu.Item key="en">English</Menu.Item>
      <Menu.Item key="pt">Português</Menu.Item>
    </Menu>
  );

  const links = [
    { link: <img src={whiteLogo} alt="Angora" />, to: '/' },
    { link: t['TermsAndConditions'], to: '/' },
    { link: t['PrivacyPolicy'], to: '/' },
    { link: t['CookiesPolicy'], to: '/' },
  ];

  return (
    <Container align="middle" justify="space-between">
      <Col>
        <Links align="middle" gutter={[48, 48]}>
          {links.map((link, index) => (
            <Col key={index}>
              <Link color="white" to={link.to} css={linkCss}>
                {link.link}
              </Link>
            </Col>
          ))}
        </Links>
      </Col>

      <Col>
        <Row>
          <AntdDropdown overlay={langs}>
            <Row align="middle">
              <Button type="text" style={{ color: '#fff' }}>
                {isPortuguese ? 'Português' : 'English'}
              </Button>
              <Space width={5} />
              <CaretDownFilled style={{ color: '#fff', fontSize: '10px' }} />
            </Row>
          </AntdDropdown>
        </Row>
      </Col>
    </Container>
  );
};

export default SiteInfo;
