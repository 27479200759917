import { version } from '../package.json';

// SDK version
export const PACKAGE_VERSION = version;

// Platform running this code
export const PLATFORM = {
  SDK: 'SDK',
};

// Angora environment -> local or staging or production
export const ANGORA_ENV = process.env.ANGORA_ENV;

// commit sha on GitHub
export const COMMIT_SHA = process.env.COMMIT_SHA;

export const origin = window.location.hostname;

// cookies keys
// _al_a holds the token
// _al_c holds the cookie id
// _al_d holds device and customer info // DEPRECATED
export const COOKIE_KEY = {
  APPLICATION: `${process.env.COOKIE_KEY_APPLICATION || '_al_a'}_${origin}`,
  COOKIE: `${process.env.COOKIE_KEY_COOKIE || '_al_c'}_${origin}`,
  DATA: `${process.env.COOKIE_KEY_DATA || '_al_d'}_${origin}`, // DEPRECATED
};

// GraphQL endpoint
export const GRAPHQL_SDK_URL = process.env.GRAPHQL_SDK_URL;

export const gql = String.raw;
