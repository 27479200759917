const theme = {
  color: {
    darkBlue: '#111E42',
    lightBlue: '#6D7EA8',
    text: '#354675',
    white: '#FFFFFF',
    green: '#3C7176',
    pink: '#E63A54',
    gray: '#797979',
    error: '#E64239',
  },
  fontSize: {
    title: '70px',
    h1: '64px',
    h2: '50px',
    h3: '40px',
    price: '30px',
    menuLink: '25px',
    sectionTitle: '20px',
    link: '18px',
    button: '16px',
    label: '15px',
    text: '14px',
  },
  fontWeight: {
    title: '800',
    h1: '800',
    h2: '800',
    h3: '800',
    price: '600',
    menuLink: '600',
    button: '600',
    label: '600',
    text: '400',
  },
};

declare module 'styled-components' {
  export interface DefaultTheme {
    color: {
      darkBlue: string;
      lightBlue: string;
      text: string;
      white: string;
      green: string;
      pink: string;
      gray: string;
      error: string;
    };
    fontSize: {
      title: string;
      h1: string;
      h2: string;
      h3: string;
      price: string;
      menuLink: string;
      sectionTitle: string;
      link: string;
      button: string;
      label: string;
      text: string;
    };
    fontWeight: {
      title: string;
      h1: string;
      h2: string;
      h3: string;
      price: string;
      menuLink: string;
      button: string;
      label: string;
      text: string;
    };
  }
}

export default theme;
