import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    background: #fff;
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font: 14px 'Nunito Sans', sans-serif;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    font-weight: 400;
  }

  ul {
    list-style: none;
  }

  p {
    margin-bottom: 0 !important;
  }

  .ant-btn {
    font: 16px 'Nunito Sans', sans-serif !important;
    font-weight: 400 !important;
    height: 48px;
    padding: 0 24px;
    border-radius: 3px;
  }

  .ant-btn-primary[disabled] {
    background: #D2CECF !important;
    color: #fff !important;
  }

  .ant-modal-content {
    border-radius: 10px !important;
  }
`;

export default GlobalStyles;
