import { uuid as uuidv1 } from '@angora/sdk';

import { fingerprint } from './fingerprint';
import { fetchQuery } from './fetchQuery';
import { sendBeacon } from './sendBeacon';
import { changeState, produceQueue, STATES } from './queue';
import { bakeCookie, getCookieObject } from './localStorage';
import { COOKIE_KEY, PLATFORM } from './config';

const cRMutation = `mutation CRMutation($input: CRInput!) { CR(input: $input) { e } }`;

const sEEMutation = `mutation SEEMutation($input: SEEInput!) { SEE(input: $input) { e } }`;

export function endSession() {
  const now = new Date();
  const input = {
    ea: { ds: now.toString(), di: now.toISOString() },
  };

  const args = {
    request: { name: 'SEE', query: sEEMutation },
    variables: { input },
  };

  sendBeacon(args);
}

type SdkInput = {
  appName?: string | null;
  appVersion?: string | null;
};

export function getOrCreateCookieRegisterDeviceAndStartSession({ appName, appVersion }: SdkInput) {
  const now = new Date();
  const input = {
    m: fingerprint.murmur,
    s: fingerprint.size,
    sa: { ds: now.toString(), di: now.toISOString() },
    sdk: {
      p: PLATFORM.SDK,
      an: appName,
      av: appVersion,
    },
    r: document.referrer,
  };

  // @TODO - generate id on sdk before sending mutation
  const cookie = getCookieObject(COOKIE_KEY.COOKIE);
  let cookieId;
  if (!cookie || !cookie.id) {
    const uuid = uuidv1();
    cookieId = `${fingerprint.murmur}.${uuid}`;
    bakeCookie(COOKIE_KEY.COOKIE, 'id', cookieId);
  } else {
    bakeCookie(COOKIE_KEY.COOKIE, 'id', cookie.id);
    cookieId = cookie.id;
  }

  input.c = cookieId;

  const args = {
    request: { name: 'CR', query: cRMutation },
    variables: { input },
  };
  fetchQuery(args, data => {
    if (!data || !data.CR || data.CR.e) {
      bakeCookie(COOKIE_KEY.COOKIE, 'id', undefined, true);
      changeState(STATES.ERROR);
    } else {
      // @TODO - make sure we don't need this anymore since the first event
      // we're adding to the queue is an event listener for page load
      // produceQueue([{ executor: 'pageView', params: getPageViewParams({}) }]);
      changeState(STATES.SUCCESS);
    }
  });
}

export function listenToJavaScriptUnloadEvent() {
  if (window) {
    /**
     * 1. Unload queue: send all tracked events
     * 2. End pageView
     * 3. End session
     */
    window.addEventListener('beforeunload', function(event) {
      // console.log('beforeunload event', JSON.stringify(event));
      if (event.isTrusted) {
        // @TODO - instead of add endSession to queue, flush all events
        produceQueue([{ executor: 'endSession', params: {} }]);
      }
    });

    /**
     * We gonna send twice to make sure we don't loose it
     */
    window.addEventListener('unload', function(event) {
      // @TODO - check queue.length, stop everything and persist queue to cookies
    });
    changeState(STATES.SUCCESS);
  } else {
    changeState(STATES.ERROR);
  }
}
