import Text from '../modules/common/Text';

const en = {
  Pricing: 'Pricing',
  ContactUs: 'Contact us',
  GetStartedForFree: 'Get started for free',
  UseTheDataToYourAdvantage: 'Make your data work for you',
  UnderstandingYourCustomerHas:
    'Understanding your customer has never been so easy! Integrate today and create your analysis in a few minutes.',
  WantToKnowMore: 'Want to know more?',
  WithOurFreePlanYouCanStart:
    "With our Free plan you can start monitoring your platform right now! Strengthen your company's Data Driven culture.",
  TermsAndConditions: 'Terms and conditions',
  PrivacyPolicy: 'Privacy policy',
  CookiesPolicy: 'Cookies policy',
  Analysis: 'Analysis',
  GetMoreDataInLessTime: 'Get more data in less time',
  InAFewClicksMonitorYourPlatform:
    'In a few clicks, monitor your platform and generate valuable data on how your customers interact with it.',
  ComingSoon: 'Coming soon',
  SegmentYourUsers: 'Segment your users',
  LoyaltyGrid: 'Loyalty Grid (RFM)',
  ThisGridGroupsYourCustomers: 'This grid groups your customers, from the most loyal to the one who needs attention.',
  Segments: 'Segments',
  SegmentYourUsersInAWayThat: 'Segment your users in a way that makes sense to your platform.',
  UserPerformance: 'User performance',
  SeeTheEventsThatUsersArePerforming: 'See the events that users are performing in real time.',
  DiscoverTheWeaknessesOfYourApp: 'Discover the weaknesses of your app experience',
  SeeWhichPartsOfYourPlatform:
    'See which parts of your platform are most responsible for drop-offs and take focused measures to avoid churn.',
  EveryDayYourPlatform:
    'Every day your platform receives thousands of new interactions. Learn how to use this data to your advantage and plan your next steps.',
  GetToKnowOurPricingPlans: 'Get to know our pricing plans',
  WhoIsAngoraFor: 'Who is Angora for',
  ForThoseWhoWantToReduceChurn: 'For those who want to reduce churn',
  SegmentYourCustomersByType: 'Segment your customers by type of loyalty and engage users who need attention.',
  DirectDevelopmentTeamEfforts: 'Direct development team efforts',
  UnderstandWhichPartsOfThePlatform:
    "Understand which parts of the platform has more drop-offs, create analysis of the app's performance and direct the work of the devs.",
  ImproveCustomerExperience: 'Improve customer experience',
  GetValuableInsightsFromUserJourneyAnalysis:
    'Get valuable insights from user journey analysis and customer interaction with app pages.',
  ImproveSales: 'Improve sales',
  GroupNewVisitorsAccordingToTheirCharacteristics:
    'Group new visitors according to their characteristics, tastes and actions and generate targeted and personalized engagements.',
  KnowYourUsersJourney: "Know your users' journey",
  UnderstandWhereTheyCameFrom: `Understand where they came from and where they'll go. Don't make any more decisions based on "What if ...". Use the data to your advantage.`,
  BoostYourEngagement: 'Boost your engagement',
  CreatePersonalizedCampaignsBased:
    'Create personalized campaigns based on user’s data or integrate with your favorite CRM.',
  OurPlans: 'Our plans',
  PricingTag: '$',
  Month: '/month',
  Basic: 'Basic',
  ForBeginnersInTheDataDrivenCulture: 'For beginners in the Data Driven culture',
  OneUser: '1 user',
  AccessToPredeterminedAnalysis: 'Access to predetermined analysis',
  CancelAnytime: 'Cancel anytime',
  Growth: 'Growth',
  ForPlatformsWithTeams: 'For platforms with teams',
  UnlimitedUsers: 'Unlimited users',
  FullPlatformAccess: 'Full platform access',
  UnlimitedReporting: 'Unlimited reporting',
  StartNow: 'Start now',
  WorkspaceCustomDomain: 'Workspace custom domain',
  UsersWithAccess: 'Users with access',
  AccessToHome: 'Access to Home',
  CustomEvents: 'Custom events',
  Projects: 'Projects',
  CreateOnlineReports: 'Create online reports',
  DataDownload: 'Data download (.CSV)',
  DataStorage: 'Data storage',
  Dashboards: 'Dashboards',
  Analyses: 'Analysis',
  FunnelAnalysis: 'Funnel analysis',
  RecurrenceAnalysis: 'Recurrence analysis',
  UserTypeAnalysis: 'User type analysis',
  ClusterAnalysis: 'Cluster analysis',
  Unlimited: 'unlimited',
  OneYear: '1 year',
  PlanDetails: 'Plan details',
  LoyaltyGridRFRM: 'Loyalty grid (RF, RFM, RFE, RFD)',
  UserSegmentationCohorts: 'User segmentation (Cohorts)',
  CreateEngagementCampaigns: 'Create engagement campaigns',
  IntegrationWithCRMs: 'Integration with CRMs',
  Notifications: 'Notifications',
  PermissionGroups: 'Permission groups',
  CreateGoalsForYourTeams: 'Create goals for your teams',
  ToCancelAnytime: 'Cancel anytime',
  Triggers: 'Triggers',
  ChooseBasicPlan: 'Choose Basic plan',
  ChooseGrowthPlan: 'Choose Growth plan',
  YoureAlmostThere: "You're almost there",
  CompleteTheFormWith: 'Complete the form with your data.',
  DontWorryWeWontFill: "Don't worry: we won't fill your email with spam :)",
  AfterFillingAndForwardingTheData: 'After filling and forwarding the data,',
  ChooseTheBestDateAndTime: 'choose the best date and time',
  ToScheduleAProductOnboardingCall: 'to schedule a product onboarding call.',
  TheCallHasAnAverage: 'The call has an average',
  DurationOfFortyMinutes: 'duration of 40 minutes.',
  AtTheEndOfTheCall: 'At the end of the call',
  YouWillReceiveYourAccess: 'you will receive your access',
  ToAngora: 'to Angora.',
  RegistrationInAngora: 'Registration in Angora',
  Name: 'Name',
  LastName: 'Last name',
  ContactPhone: 'Contact phone',
  CorporateEmail: 'Corporate email',
  Company: 'Company',
  Register: 'Register',
  Resources: 'Resources',
  Blog: 'Blog',
  Doc: 'Doc',
  ReadOurContentAboutTheWorld: 'Read our content about the world of data analysis and how to use it to your advantage.',
  TechnicalQuestionsOnHowToApplyAngora:
    'Technical questions on how to apply Angora on your platform? Check out our documentation!',
  PageNotFound: 'Page not found',
  ThePageYouAreLookingFor: 'The page you are looking for was not found. Make sure the URL you entered is correct.',
  GoToHome: 'Go to Home',
  RequiredField: 'Required field',
  InvalidEmailCheckIf: 'Invalid email. Check if the email was typed correctly.',
  InvalidPhoneCheckIf: 'Invalid phone. Check if the phone was typed correctly.',
  WaitForContact: 'Wait for contact',
  ScheduleCall: 'Schedule Call',
  RegistrationSuccessful: 'Registration successful!',
  WeWillGetInTouchSoonIfYouPreferYouCanScheduleACallTightNow:
    'We will get in touch soon. If you prefer, you can schedule a call right now.',
};

export default en;
export type TranslationKeys = typeof en;
