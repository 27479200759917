// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-pt-tsx": () => import("./../../src/pages/404.pt.tsx" /* webpackChunkName: "component---src-pages-404-pt-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-pt-tsx": () => import("./../../src/pages/contact.pt.tsx" /* webpackChunkName: "component---src-pages-contact-pt-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-pt-tsx": () => import("./../../src/pages/index.pt.tsx" /* webpackChunkName: "component---src-pages-index-pt-tsx" */),
  "component---src-pages-pricing-en-tsx": () => import("./../../src/pages/pricing.en.tsx" /* webpackChunkName: "component---src-pages-pricing-en-tsx" */),
  "component---src-pages-pricing-pt-tsx": () => import("./../../src/pages/pricing.pt.tsx" /* webpackChunkName: "component---src-pages-pricing-pt-tsx" */)
}

