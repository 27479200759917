import React from 'react';
import styled, { css, FlattenInterpolation } from 'styled-components';

const commonStyle = css<TextProps>`
  color: ${p => (p.color ? p.theme.color[p.color] : p.theme.color.text)};
  font-size: ${p => (p.font ? p.theme.fontSize[p.font] : p.theme.fontSize.text)};
  font-weight: ${p => (p.fontWeight ? p.fontWeight : p.font ? p.theme.fontWeight[p.font] : p.theme.fontWeight.text)};
  margin: 0px;
  ${p => p.css}
  ${p => p.center && `text-align: center;`}
  ${p => p.lineHeight && `line-height: ${p.lineHeight}`}
`;

const Paragraph = styled.p<TextProps>`
  ${commonStyle}
`;

const Span = styled.span<TextProps>`
  ${commonStyle}
`;

export interface TextProps {
  font?: string;
  fontWeight?: string;
  lineHeight?: string;
  color?: string;
  css?: FlattenInterpolation<any>;
  onClick?: () => void;
  span?: boolean;
  center?: boolean;
  children?: React.ReactNode;
}

const Text = (props: TextProps) => {
  return props.span ? <Span {...props} /> : <Paragraph {...props} />;
};

export default Text;
