import { useState, useEffect } from 'react';

let lastScrollTime = new Date().getTime();
let lastScroll = 0;

export function useScroll(scrollTop: number) {
  const [scrollingUp, setScrollingUp] = useState(false);
  const [isOver, setIsOver] = useState(false);

  const handleScroll = () => {
    const scrollY = document.documentElement.scrollTop;
    const hasPassed = scrollY > scrollTop;

    if (hasPassed && !isOver) {
      setIsOver(true);
    } else if (!hasPassed && isOver) {
      setIsOver(false);
    }

    if (new Date().getTime() - lastScrollTime > 250) {
      handleScrollY();
      lastScrollTime = new Date().getTime();
    }
  };

  const handleScrollY = () => {
    const scrollY = document.documentElement.scrollTop;
    const hasPassed = scrollY > scrollTop;

    if (lastScroll > scrollY && hasPassed) {
      setScrollingUp(true);
    } else if (lastScroll < scrollY) {
      setScrollingUp(false);
    } else {
      setScrollingUp(false);
    }

    lastScroll = scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isOver, scrollingUp];
}
