import { COOKIE_KEY } from './config';
import { base64, unbase64 } from './base64';

export interface SDKCookieApplication {
  token: string | null;
  appversion: string | null;
  appplatform?: string | null;
  timezone?: string | null;
}

export interface SDKCookie {
  id: string;
}

export type CookieObject = SDKCookie | SDKCookieApplication;

const cookies = Object.values(COOKIE_KEY);

export const getAllowedKeys = (cookieKey?: string | null): string | null => {
  // @TODO - maybe throw an error
  if (!cookieKey) return null;

  if (cookieKey.split('_').length < 4) return null;

  const keys = cookieKey.split('_');
  return `_${keys[1]}_${keys[2]}`;
};

export const allowedKeys = {
  _al_a: ['token', 'appversion', 'appplatform', 'timezone'],
  _al_c: ['id'],
};

export let credentials = {};
export let encodedCredentials = '%7B%7D';

export function getCookieObject(cookie: string): CookieObject | {} {
  // Only update allowed cookies
  if (!cookies.includes(cookie)) return {};

  // Get cookie value
  let cookieValue = localStorage.getItem(cookie) || '';
  if (cookieValue.length > 0) {
    // Decrypt
    cookieValue = unbase64(cookieValue);
    // Parse string to object
    return JSON.parse(cookieValue);
  }

  return {};
}

export function bakeCookie(cookie: string, key: string, value: string | undefined, remove = false) {
  // Only update allowed cookies
  if (!cookies.includes(cookie)) return;

  // Only update cookie with allowed keys
  if (!allowedKeys[getAllowedKeys(cookie)].includes(key)) return;

  if (remove) {
    document.cookie = `${cookie}=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    localStorage.setItem(cookie, '');
    return;
  }

  // Get cookie object
  const angoraCookie = getCookieObject(cookie);

  // Update cookie object key with value
  angoraCookie[key] = value;
  // Rollback to string
  let cookieValue = JSON.stringify(angoraCookie);
  // Encrypt
  cookieValue = base64(cookieValue);

  updateCredentials(cookie, cookieValue);
  localStorage.setItem(cookie, cookieValue);
}

export function updateCredentials(cookie, cookieValue) {
  credentials = {
    ...credentials,
    [cookie]: cookieValue,
  };
  encodedCredentials = encodeURIComponent(JSON.stringify(credentials));
}
