import { bakeCookie } from './localStorage';
import { COOKIE_KEY, PACKAGE_VERSION, PLATFORM } from './config';
import { changeState, STATES } from './queue';

export function bootstrap({ token }: { token: string }): void {
  // @TODO - changeState(STATES.ERROR) if cookies are not allowed
  // navigator.doNotTrack
  // return "1" if DNT is enabled;
  // return "0" if the user opted-in for tracking;
  // otherwise return "unspecified"

  // bake cookies
  bakeCookie(COOKIE_KEY.APPLICATION, 'token', token);
  bakeCookie(COOKIE_KEY.APPLICATION, 'appversion', PACKAGE_VERSION);
  bakeCookie(COOKIE_KEY.APPLICATION, 'appplatform', PLATFORM.SDK);
  bakeCookie(COOKIE_KEY.APPLICATION, 'timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

  // change state to success
  changeState(STATES.SUCCESS);
}
