import { useCallback } from 'react';
import { useNavigate } from '@reach/router';

import useTranslation from './useTranslation';

const useHistory = () => {
  const navigate = useNavigate();
  const { isPortuguese } = useTranslation();

  const push = useCallback(
    (to: string) => {
      navigate(isPortuguese ? `/pt${to}` : to);
    },
    [isPortuguese, navigate],
  );

  return { push };
};

export default useHistory;
