import { JSObject } from '@angora/types';

import { sendBeacon } from './sendBeacon';

import { changeState, produceQueue, STATES } from './queue';

const cIMutation = `mutation CIMutation($input: CIInput!) { CI(input: $input) { e } }`;

export function executeIdentify({ customerId, properties = {} }: { customerId: string; properties: JSObject<string> }) {
  // @TODO - improve this, maybe notify us that someone is trying to identify without customerId
  if (!customerId) {
    changeState(STATES.SUCCESS);
    return;
  }

  let p = '{}';

  try {
    const keys = Object.keys(properties);
    if (keys.length > 0) {
      p = JSON.stringify(properties);
    }
  } catch (err) {
    // @TODO - do something if user sends invalid object
  }

  const input = {
    i: customerId,
    ...(p.length > 2 ? { p } : {}),
  };

  const args = {
    request: { name: 'CI', query: cIMutation },
    variables: { input },
  };

  sendBeacon(args);
  changeState(STATES.SUCCESS);
}

export function identify(customerId: string, properties: JSObject<string> = {}) {
  produceQueue([{ executor: 'executeIdentify', params: { customerId, properties } }]);
}
