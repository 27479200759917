import { GRAPHQL_SDK_URL } from './config';

import { log } from './logger';
import { encodedCredentials } from './localStorage';

type RequestNode = {
  name: string;
  query: string;
};

type Variables = {
  [key: string]: any;
};

export type FetchQuery = {
  request: RequestNode;
  variables: Variables;
};

function getRequestBody(request: RequestNode, variables: Variables) {
  return JSON.stringify({
    name: request.name,
    query: request.query,
    variables,
  });
}

function getDefaultHeaders() {
  return {
    Accept: 'application/json',
    'Content-type': 'application/json',
  };
}

function handleResponse(response) {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }

  return response.text();
}

export function fetchQuery(args: FetchQuery, callback?: (data: any) => void): Promise<any> {
  const { request, variables } = args;
  const body = getRequestBody(request, variables);

  const headers = { ...getDefaultHeaders() };

  return fetch(GRAPHQL_SDK_URL + `?cookie=${encodedCredentials}`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers,
    body,
  })
    .then(response => {
      // @TODO - improve this to log when application token is wrong
      if (!response.ok || response.status !== 200) {
        throw new Error('Network is not ok');
      }
      return handleResponse(response);
    })
    .then(result => {
      if (result.errors) {
        throw result.errors;
      }

      if (typeof callback === 'function') {
        return callback(result.data);
      }
    })
    .catch(err => {
      log('Error', Array.isArray(err) ? err : err.message);
      if (typeof callback === 'function') {
        return callback(null);
      }
    });
}
