import Fingerprint2 from 'fingerprintjs2';

import { changeState, STATES } from './queue';

export type FingerprintResult = {
  murmur: string;
  size: number;
};

export const fingerprint = {
  murmur: '',
  size: 0,
};

function encryptComponents(components): FingerprintResult {
  const values = components.map(component => component.value);
  return {
    murmur: Fingerprint2.x64hash128(values.join(''), 31),
    size: new Blob([values.join('')]).size,
  };
}

export function calculateFingerprint() {
  function fingerprint2() {
    Fingerprint2.get(components => {
      const result = encryptComponents(components);
      fingerprint.murmur = result.murmur;
      fingerprint.size = result.size;
      changeState(STATES.SUCCESS);
    });
  }

  if (window.requestIdleCallback) {
    window.requestIdleCallback(() => {
      fingerprint2();
    });
  } else {
    setTimeout(() => {
      fingerprint2();
    }, 500);
  }
}
