import { useMemo } from 'react';
import { useLocation } from '@reach/router';

import en, { TranslationKeys } from './en';
import pt from './pt';

const ptRegex = new RegExp('pt', 'i');

interface Translation {
  t: TranslationKeys;
  redirectPath: 'pt' | '';
  isPortuguese: boolean;
}

const useTranslation = (): Translation => {
  const location = useLocation();

  const isPortuguese = useMemo(() => {
    return ptRegex.test(location.pathname);
  }, [location.pathname]);

  const t = isPortuguese ? pt : en;
  const redirectPath = isPortuguese ? 'pt' : '';

  return { t, redirectPath, isPortuguese };
};

export default useTranslation;
