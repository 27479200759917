// inspired by: https://stackoverflow.com/questions/105034/how-to-create-guid-uuid/21963136#21963136

function genNumber() {
  // const r = Math.random();
  const time = new Date().getTime();
  return (Number(`0.${time}`) * 0xffffffff) | 0;
}

export default function uuid() {
  const lut = [];
  for (let i = 0; i < 256; i++) {
    lut[i] = (i < 16 ? '0' : '') + i.toString(16);
  }
  return (function() {
    const d0 = genNumber();
    const d1 = genNumber();
    const d2 = genNumber();
    const d3 = genNumber();
    /* eslint-disable */
    return `${lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]}-${
      lut[d1 & 0xff]
    }${lut[(d1 >> 8) & 0xff]}-${lut[((d1 >> 16) & 0x0f) | 0x40]}${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${
      lut[(d2 >> 8) & 0xff]
    }-${lut[(d2 >> 16) & 0xff]}${lut[(d2 >> 24) & 0xff]}${lut[d3 & 0xff]}${lut[(d3 >> 8) & 0xff]}${
      lut[(d3 >> 16) & 0xff]
    }${lut[(d3 >> 24) & 0xff]}`;
    /* eslint-enable */
  })();
}
