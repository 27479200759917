import React, { useMemo } from 'react';
import { Row, Col, Dropdown, Menu } from 'antd';
import styled, { css } from 'styled-components';
import { useLocation } from '@reach/router';
import { animated, useSpring, config } from 'react-spring';
import { CopyOutlined, FolderOutlined, DownOutlined } from '@ant-design/icons';

import { HOSTNAME } from '../../config';

import useTranslation from '../../locales/useTranslation';
import useHistory from '../../locales/useHistory';

import Text from '../common/Text';
import Link from '../common/Link';

import logo from '../../images/logo.svg';
import whiteLogo from '../../images/headerWhiteLogo.svg';
import Space from '../common/Space';

const scrollingUpCss = css`
  padding: 24px 48px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(17, 30, 66, 0.1);
  opacity: 1 !important;
  transition: opacity 0.2s cubic-bezier(0.55, 0, 0.55, 0.2);
`;

interface ContainerProps {
  isOver: boolean;
  scrollingUp: boolean;
}
const Container = styled(animated.div)<ContainerProps>`
  padding: 48px 48px 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 1000;

  @media (max-width: 970px) {
    display: none;
  }
`;

const Button = styled(Row)<{ isPlansRoute: boolean }>`
  border: ${p => (p.isPlansRoute ? '1px solid #fff' : '1px solid #111e42')};
  border-radius: 3px;
  padding: 13px 24px;
  cursor: pointer;
`;

const SubMenu = styled(Row)`
  width: 200px;
  padding: 16px;
  border-radius: 6px;
  flex-wrap: unset !important;
  align-items: baseline;
  transition: background 0.2s;

  &:hover {
    background: #f2f6ff;
  }
`;

const pricingRegex = new RegExp('/pricing', 'i');

interface HeaderProps {
  isOver: boolean;
  scrollingUp: boolean;
}

const Header = ({ isOver, scrollingUp }: HeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const headerSpring = useSpring({
    from: { opacity: 0, marginTop: -50 },
    to: { opacity: 1, marginTop: 0 },
    config: config.molasses,
    delay: 300,
  });

  const resourcesSubMenus = [
    {
      icon: CopyOutlined,
      title: t['Blog'],
      // description: t['ReadOurContentAboutTheWorld'],
      description: t['Blog'],
      href: `https://blog.${HOSTNAME}`,
    },
    {
      icon: FolderOutlined,
      title: t['Doc'],
      //description: t['TechnicalQuestionsOnHowToApplyAngora'],
      description: t['Doc'],
      href: `https://docs.${HOSTNAME}`,
    },
  ];

  const resourcesOverlay = (
    <Menu style={{ padding: 8, borderRadius: 6 }}>
      {resourcesSubMenus.map(subMenu => (
        <Menu.Item style={{ padding: 0 }} key={subMenu.title}>
          <Row>
            <a href={subMenu.href} target="_blank" rel="noopener noreferrer">
              <SubMenu gutter={[8, 0]}>
                <Col style={{ paddingLeft: 0 }}>
                  <subMenu.icon style={{ fontSize: 16, color: '#6D7EA8' }} />
                </Col>
                <Col style={{ paddingRight: 0 }}>
                  <Text font="button">{subMenu.title}</Text>
                  <Space height={6} />
                  <Text font="button" color="lightBlue">
                    {subMenu.description}
                  </Text>
                </Col>
              </SubMenu>
            </a>
          </Row>
        </Menu.Item>
      ))}
    </Menu>
  );

  const isPlansRoute = useMemo(() => pricingRegex.test(location.pathname), [location.pathname]);

  return (
    <Container /* style={headerSpring} */ isOver={isOver} scrollingUp={scrollingUp}>
      <Row align="middle" justify="space-between">
        <Row gutter={[48, 0]} align="middle">
          <Col>
            <Link to="/">
              <img src={isPlansRoute ? whiteLogo : logo} alt="Angora" />
            </Link>
          </Col>
          <Col>
            <Link font="link" color={isPlansRoute ? 'white' : 'darkBlue'} to="/pricing">
              {t['Pricing']}
            </Link>
          </Col>
          <Col>
            <Link font="link" color={isPlansRoute ? 'white' : 'darkBlue'} to="/contact">
              {t['ContactUs']}
            </Link>
          </Col>
          <Col>
            <Dropdown overlay={resourcesOverlay}>
              <Row align="middle" style={{ cursor: 'pointer' }}>
                <Text font="link" color={isPlansRoute ? 'white' : 'darkBlue'}>
                  {t['Resources']}
                </Text>
                <Space width={5} />
                <DownOutlined style={{ fontSize: 12, color: '#111E42' }} />
              </Row>
            </Dropdown>
          </Col>
        </Row>

        <Button justify="center" align="middle" onClick={() => history.push('/contact')} isPlansRoute={isPlansRoute}>
          <Text font="button" color={isPlansRoute ? 'white' : 'darkBlue'}>
            {t['GetStartedForFree']}
          </Text>
        </Button>
      </Row>
    </Container>
  );
};

export default Header;
