import { JSObject } from '@angora/types';

import { sendBeacon } from './sendBeacon';

import { changeState, produceQueue, STATES } from './queue';

const eAMutation = `mutation EAMutation($input: EAInput!) { EA(input: $input) { e } }`;

export function executeTrack({ code, properties = {} }: { code: string; properties: JSObject<string> }) {
  // @TODO - improve this, maybe notify us that someone is trying to track without code
  if (!code) {
    changeState(STATES.SUCCESS);
    return;
  }

  let p = '{}';

  try {
    const keys = Object.keys(properties);
    if (keys.length > 0) {
      p = JSON.stringify(properties);
    }
  } catch (err) {
    // @TODO - do something if user sends invalid object
  }

  const now = new Date();
  const input = {
    c: code,
    ...(p.length > 2 ? { p } : {}),
    ra: { ds: now.toString(), di: now.toISOString() },
  };

  const args = {
    request: { name: 'EA', query: eAMutation },
    variables: { input },
  };

  sendBeacon(args);
  changeState(STATES.SUCCESS);
}

export function track(code: string, properties: JSObject<string> = {}) {
  produceQueue([{ executor: 'executeTrack', params: { code, properties } }]);
}
