const pt = {
  Pricing: 'Ver planos',
  ContactUs: 'Contate-nos',
  GetStartedForFree: 'Começar gratuitamente',
  UseTheDataToYourAdvantage: 'Use os dados ao seu favor',
  UnderstandingYourCustomerHas:
    'Nunca foi tão fácil entender seu cliente! Integre hoje e crie suas análises em minutos.',
  WantToKnowMore: 'Quer descobrir mais?',
  WithOurFreePlanYouCanStart:
    'Com o nosso plano Free você começa a monitorar sua plataforma agora mesmo! Fortaleça a cultura Data Driven da sua empresa.',
  TermsAndConditions: 'Termos e condições',
  PrivacyPolicy: 'Política de privacidade',
  CookiesPolicy: 'Política de cookies',
  Analysis: 'Análise',
  GetMoreDataInLessTime: 'Mais dados em menos tempo',
  ComingSoon: 'Em breve',
  SegmentYourUsers: 'Segmente seus usuários',
  LoyaltyGrid: 'Grid de lealdade (RFM)',
  ThisGridGroupsYourCustomers: 'Esse grid agrupa seus clientes, desde o mais leal até aquele que precisa de atenção.',
  Segments: 'Segmentos',
  SegmentYourUsersInAWayThat: 'Segmente seus usuários de acordo com critérios que fazem sentido para sua plataforma.',
  UserPerformance: 'Performance dos usuários',
  SeeTheEventsThatUsersArePerforming: 'Veja os eventos que os usuários estão performando em tempo real.',
  InAFewClicksMonitorYourPlatform:
    'Em poucos cliques, monitore a sua plataforma e gere dados valiosos de como seus clientes interagem com ela.',
  DiscoverTheWeaknessesOfYourApp: 'Descubra os pontos fracos da experiência do seu app',
  SeeWhichPartsOfYourPlatform:
    'Veja quais partes da sua plataforma mais afastam seus clientes e tome medidas focadas para evitar o churn.',
  EveryDayYourPlatform:
    'Todo dia sua plataforma recebe milhares de novas interações. Aprenda a utilizar esses dados para planejar seus próximos passos.',
  GetToKnowOurPricingPlans: 'Conheça nossos planos',
  WhoIsAngoraFor: 'Para quem é o Angora',
  ForThoseWhoWantToReduceChurn: 'Para quem quer reduzir o churn',
  SegmentYourCustomersByType:
    'Segmente seus clientes por tipo de lealdade e engaje os usuários que precisam de atenção.',
  DirectDevelopmentTeamEfforts: 'Direcionar os esforços do desenvolvimento',
  UnderstandWhichPartsOfThePlatform:
    'Entenda qual parte da plataforma tem maior abandono, crie análises sobre a performance do app e direcione o trabalho dos devs.',
  ImproveCustomerExperience: 'Melhorar a experiência dos clientes',
  GetValuableInsightsFromUserJourneyAnalysis:
    'Consiga insights valiosos a partir de análises de jornada do usuário e da interação dos clientes com cada página.',
  ImproveSales: 'Melhorar as vendas',
  GroupNewVisitorsAccordingToTheirCharacteristics:
    'Agrupe os novos visitantes de acordo com as suas características, gostos e ações e gere engajamentos direcionados e personalizados.',
  KnowYourUsersJourney: 'Conheça a jornada dos seus usuários',
  UnderstandWhereTheyCameFrom:
    'Entenda de onde eles vieram e para onde eles vão. Não tome mais decisões baseados no "E se...". Use os dados ao seu favor.',
  BoostYourEngagement: 'Impulsione o engajamento',
  CreatePersonalizedCampaignsBased:
    'Crie campanhas personalizadas baseadas nos dados de cada usuário ou integre com seu CRM favorito.',
  OurPlans: 'Nossos planos',
  PricingTag: 'R$',
  Month: '/mês',
  Basic: 'Básico',
  ForBeginnersInTheDataDrivenCulture: 'Para iniciantes na cultura Data Driven',
  OneUser: '1 usuário',
  AccessToPredeterminedAnalysis: 'Acesso a análises pré-determinadas',
  CancelAnytime: 'Cancele a qualquer hora',
  Growth: 'Growth',
  ForPlatformsWithTeams: 'Para plataformas com times',
  UnlimitedUsers: 'Usuários ilimitados',
  FullPlatformAccess: 'Acesso à plataforma completa',
  UnlimitedReporting: 'Relatórios ilimitados',
  StartNow: 'Começar agora',
  WorkspaceCustomDomain: 'Domínio do workspace personalizado',
  UsersWithAccess: 'Usuários com acesso',
  AccessToHome: 'Acesso a Home',
  CustomEvents: 'Eventos personalizados',
  Projects: 'Projetos',
  CreateOnlineReports: 'Gerar relatórios online',
  DataDownload: 'Download dos dados (.CSV)',
  DataStorage: 'Armazenamento dos dados',
  Dashboards: 'Dashboards',
  Analyses: 'Análises',
  FunnelAnalysis: 'Análise do tipo funil',
  RecurrenceAnalysis: 'Análise do tipo recorrência',
  UserTypeAnalysis: 'Análise do tipo dos usuários',
  ClusterAnalysis: 'Análise do tipo cluster',
  Unlimited: 'ilimitado',
  OneYear: '1 ano',
  PlanDetails: 'Detalhes dos planos',
  LoyaltyGridRFRM: 'Grid de lealdade (RF, RFM, RFE, RFD)',
  UserSegmentationCohorts: 'Segmentação de usuários (Cohorts)',
  CreateEngagementCampaigns: 'Criar campanhas de engajamento',
  IntegrationWithCRMs: 'Integração com CRMs',
  Notifications: 'Notificações',
  PermissionGroups: 'Grupos de permissões',
  CreateGoalsForYourTeams: 'Criar metas para os times',
  ToCancelAnytime: 'Cancelar a qualquer momento',
  Triggers: 'Triggers (Gatilhos para ações)',
  ChooseBasicPlan: 'Escolher plano Básico',
  ChooseGrowthPlan: 'Escolher plano Growth',
  YoureAlmostThere: 'Você está quase lá',
  CompleteTheFormWith: 'Preencha seus dados',
  DontWorryWeWontFill: 'no formulário ao lado. Não se preocupe: não vamos lotar seu email com spams :)',
  AfterFillingAndForwardingTheData: 'Após preencher e encaminhar os dados,',
  ChooseTheBestDateAndTime: 'escolha a melhor data e hora',
  ToScheduleAProductOnboardingCall: 'para agendar uma call de onboarding do produto.',
  TheCallHasAnAverage: 'A call tem uma média de',
  DurationOfFortyMinutes: 'duração de 40 minutos.',
  AtTheEndOfTheCall: 'Ao final da call',
  YouWillReceiveYourAccess: 'você receberá seu acesso',
  ToAngora: 'ao Angora.',
  RegistrationInAngora: 'Cadastro no Angora',
  Name: 'Nome',
  LastName: 'Sobrenome',
  ContactPhone: 'Telefone de contato',
  CorporateEmail: 'Email coorporativo',
  Company: 'Empresa',
  Register: 'Cadastrar',
  Resources: 'Recursos',
  Blog: 'Blog',
  Doc: 'Doc',
  ReadOurContentAboutTheWorld: 'Leia nossos conteúdos sobre o mundo de análises de dados e como usá-lo ao seu favor.',
  TechnicalQuestionsOnHowToApplyAngora:
    'Dúvidas técnicas de como aplicar o Angora na sua plataforma? Conheça nossa documentação!',
  PageNotFound: 'Página não encontrada',
  ThePageYouAreLookingFor:
    'A página que está procurando não foi encontrada. Verifique se a URL que você digitou está correta.',
  GoToHome: 'Ir para Home',
  RequiredField: 'Campo obrigatório',
  InvalidEmailCheckIf: 'Email inválido. Verifique se o email foi digitado corretamente.',
  InvalidPhoneCheckIf: 'Telefone inválido. Verifique se o telefone foi digitado corretamente.',
  WaitForContact: 'Aguardar contato',
  ScheduleCall: 'Agendar Call',
  RegistrationSuccessful: 'Cadastro realizado com sucesso!',
  WeWillGetInTouchSoonIfYouPreferYouCanScheduleACallTightNow:
    'Entraremos em contato em breve para marcar uma call de onboarding. Se preferir, escolha o horário da sua call agora mesmo.',
};

export default pt;
