import { sendBeacon } from './sendBeacon';

import { changeState, produceQueue, STATES } from './queue';

const PVMutation = `mutation PVMutation($input: PVInput!) { PV(input: $input) { e } }`;

/* private variables */
let _route = location.pathname;
let _sequence = 0;
let _source = null;

export function pageView({ now, route, loadingTime, sequence, source }) {
  const input = {
    va: { ds: now.toString(), di: now.toISOString() },
    r: route,
    lt: loadingTime,
    sq: sequence,
    s: source,
  };

  const args = {
    request: { name: 'PV', query: PVMutation },
    variables: { input },
  };

  sendBeacon(args);
  changeState(STATES.SUCCESS);
}

type PageViewParams = {
  route?: string;
  sequence?: number;
  source?: string | null;
};

export function getPageViewParams({ route = _route, sequence = _sequence, source = _source }: PageViewParams) {
  const now = new Date();
  const { timing } = window.performance;
  const loadingTime = timing.loadEventEnd - timing.navigationStart;

  return {
    now,
    route,
    loadingTime,
    sequence,
    source,
  };
}

export function listenToNavigation() {
  if (window) {
    window.addEventListener('load', function(event) {
      const bodyList = document.querySelector('body');
      const observer = new MutationObserver(() => {
        if (_route != location.pathname) {
          // source becomes old
          _source = _route;
          // source becomes new route
          _route = location.pathname;
          // increase sequence by one
          _sequence++;
          produceQueue([
            {
              executor: 'pageView',
              params: getPageViewParams({ route: _route, sequence: _sequence, source: _source }),
            },
          ]);
        }
      });

      // if (bodyList) {
      observer.observe(bodyList, { childList: true, subtree: true });
      // } else {
      //   changeState(STATES.ERROR);
      // }
    });
    changeState(STATES.SUCCESS);
  } else {
    changeState(STATES.ERROR);
  }
}
