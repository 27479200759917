import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { useLocation } from '@reach/router';

import useTranslation from '../../locales/useTranslation';
import useHistory from '../../locales/useHistory';

import Text from '../common/Text';
import Space from '../common/Space';

import SiteInfo from './SiteInfo';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px 0;
  background: ${p => p.theme.color.darkBlue};
`;

const Container = styled.div`
  width: 100%;
  max-width: 580px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const titleCss = css`
  @media (max-width: 992px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const isHomeRoute = useMemo(
    () => location.pathname === '/' || location.pathname === '/pt' || location.pathname === '/pt/',
    [location.pathname],
  );

  return (
    <>
      {isHomeRoute && (
        <Wrapper>
          <Container>
            <Text font="h2" color="white" center css={titleCss}>
              {t['WantToKnowMore']}
            </Text>

            <Space height={26} />
            <Text font="link" color="white" center>
              {t['WithOurFreePlanYouCanStart']}
            </Text>

            <Space height={36} />
            <Button type="default" onClick={() => history.push('/contact')}>
              {t['GetStartedForFree']}
            </Button>
          </Container>
        </Wrapper>
      )}
      <SiteInfo />
    </>
  );
};

export default Footer;
